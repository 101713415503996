import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'

import { Features } from 'packages/optimizely'
import { text } from 'packages/styles'
import { Switch } from 'packages/common'
import {
  DateFormat,
  format,
  formatInTimeZone,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import type { Reservation } from 'app/store/reservations'
import { gwColors, gwText } from 'app/styles'

const TIMEZONE_PREFERENCE_KEY = 'resDateInfoTimezonePreference'

export enum ResDateInfoTestIds {
  UnitTimezoneSwitch = 'ResDateInfo__UnitTimezoneSwitch',
}

const St = {
  Cell: styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;
    box-sizing: content-box;
    text-align: center;

    &:not(:first-of-type) {
      border-left: 1px solid ${gwColors.uiDivider};
      padding-left: 10px;
    }
  `,
  ResDateInfo: styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  `,
  ResDateInfoContainer: styled.div`
    padding: 16px 0;
    border-bottom: 1px solid ${gwColors.uiDivider};
    border-top: 1px solid ${gwColors.uiDivider};
    margin: 16px 0;
    display: flex;
    flex-direction: row;
  `,
  SectionTitle: styled.div`
    ${gwText.bodySmallBold};
  `,
  Text: styled.p`
    ${text.bodyRegularSmall};
    margin: 0;
  `,
  TimezoneSwitch: styled(Switch)`
    // This is a hack to make the switch smaller, since we cannot properly adjust height/width
    transform: scale(0.7);
  `,
  TimezoneSwitchContainer: styled.div`
    ${gwText.bodySmallBold};
    color: ${gwColors.black};
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 10px;
    border-left: 1px solid ${gwColors.uiDivider};
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    checkIn: t(Slugs.checkIn),
    checkOut: t(Slugs.checkOut),
    unitTimezone: t(Slugs.unitTimezone),
  }
}

export interface ResDateInfoProps {
  res: Reservation
  unitTimezone: string
}

export const ResDateInfo: React.FC<ResDateInfoProps> = React.memo(
  ({ res, unitTimezone }) => {
    const strings = useTranslations()
    const [decision] = useDecision(Features.GUEST_STAY_CHECKIN_CHECKOUT_TIME)
    const [showInUnitTimezone, setShowInUnitTimezone] = React.useState<boolean>(
      () => {
        const savedPreference = localStorage.getItem(TIMEZONE_PREFERENCE_KEY)
        return savedPreference ? JSON.parse(savedPreference) : false
      },
    )
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const onToggleCheckInCheckOutTimezone = React.useCallback(() => {
      setShowInUnitTimezone(prev => {
        const newValue = !prev
        localStorage.setItem(TIMEZONE_PREFERENCE_KEY, JSON.stringify(newValue))
        return newValue
      })
    }, [])

    const checkInText =
      decision.enabled && showInUnitTimezone
        ? formatInTimeZone(res.checkIn, unitTimezone, DateFormat.Full)
        : format(res.checkIn, DateFormat.Full)
    const checkOutText =
      decision.enabled && showInUnitTimezone
        ? formatInTimeZone(res.checkOut, unitTimezone, DateFormat.Full)
        : format(res.checkOut, DateFormat.Full)

    return (
      <St.ResDateInfoContainer>
        <St.ResDateInfo>
          <St.Cell>
            <St.SectionTitle>{strings.checkIn}</St.SectionTitle>
            <St.Text>{checkInText}</St.Text>
          </St.Cell>

          <St.Cell>
            <St.SectionTitle>{strings.checkOut}</St.SectionTitle>
            <St.Text>{checkOutText}</St.Text>
          </St.Cell>
        </St.ResDateInfo>
        {decision.enabled && unitTimezone !== userTimezone && (
          <St.TimezoneSwitchContainer>
            {strings.unitTimezone}
            <St.TimezoneSwitch
              checked={showInUnitTimezone}
              onToggle={onToggleCheckInCheckOutTimezone}
              dataTestId={ResDateInfoTestIds.UnitTimezoneSwitch}
            />
          </St.TimezoneSwitchContainer>
        )}
      </St.ResDateInfoContainer>
    )
  },
)
