import React from 'react'

import type { BadgeType } from 'app/components/core'
import { Badge } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import { useDecision } from '@optimizely/react-sdk'
import { Features } from 'packages/optimizely'

import { isTicketCompleted, isTicketInProgress } from '../tickets.helpers'

type StatusBadgeType = Extract<BadgeType, 'aspen' | 'yellow' | 'midnight'>

const badgeChildrenMapNew: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  midnight: Slugs.notAcknowledged,
  yellow: Slugs.inProgress,
}

const badgeChildrenMapOld: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  midnight: '', // just to support type before FF will be deleted
  yellow: Slugs.bookingStatusNotFinished,
}

const getBadgeType = (status, ffEnabled): StatusBadgeType => {
  if (isTicketCompleted(status)) return 'aspen'
  if (isTicketInProgress(status) || !ffEnabled) return 'yellow'
  return 'midnight'
}

export interface TicketStatusBadgeProps {
  status: string
}

export const TicketStatusBadge: React.FC<TicketStatusBadgeProps> = React.memo(
  ({ status }) => {
    const { t } = useI18n()
    const [decision] = useDecision(Features.TICKET_IN_PROGRESS)
    const badgeType: StatusBadgeType = getBadgeType(status, decision.enabled)
    const badgeChildrenMap = decision.enabled
      ? badgeChildrenMapNew
      : badgeChildrenMapOld

    return <Badge badgeType={badgeType}>{t(badgeChildrenMap[badgeType])}</Badge>
  },
)
