import React from 'react'

import type { BadgeType } from 'app/components/core'
import { Badge } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import type { Clean } from 'app/store/cleans'
import { useDecision } from '@optimizely/react-sdk'
import { Features } from 'packages/optimizely'

type StatusBadgeType = Extract<BadgeType, 'aspen' | 'yellow' | 'midnight'>

const badgeChildrenMapNew: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  midnight: Slugs.notStarted,
  yellow: Slugs.inProgress,
}

const badgeChildrenMapOld: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  yellow: Slugs.bookingStatusNotFinished,
  midnight: '', // just to support type before FF will be deleted
}

const getBadgeType = (clean: Clean, ffEnabled: boolean): StatusBadgeType => {
  if (clean.completedAt) return 'aspen'
  if (clean.startedAt || !ffEnabled) return 'yellow'
  return 'midnight'
}

export interface CompletionStatusBadgeProps {
  clean: Clean
}

export const CompletionStatusBadge: React.FC<CompletionStatusBadgeProps> =
  React.memo(({ clean }) => {
    const { t } = useI18n()
    const [decision] = useDecision(Features.CLEAN_IN_PROGRESS)
    const badgeType: StatusBadgeType = getBadgeType(clean, decision?.enabled)
    const badgeChildrenMap = decision?.enabled
      ? badgeChildrenMapNew
      : badgeChildrenMapOld

    return <Badge badgeType={badgeType}>{t(badgeChildrenMap[badgeType])}</Badge>
  })
