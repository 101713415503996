import { DateFormat, format } from 'packages/utils/dateHelpers'

import { TicketStatus } from 'app/store/tickets'

export const formatDueDate = (date: string): string =>
  format(date, DateFormat.MonthDateAndYear)

const completedStatuses = [
  TicketStatus.RESOLVED_COMPLETED,
  TicketStatus.RESOLVED_DISMISSED,
  TicketStatus.RESOLVED_DUPLICATE,
]

const inProgressStatuses = [
  TicketStatus.ACCEPTED,
  TicketStatus.AWAITING,
  TicketStatus.RESOLUTION_PENDING,
]

export const isTicketCompleted = (status: TicketStatus): boolean => {
  return completedStatuses.includes(status)
}

export const isTicketInProgress = (status: TicketStatus): boolean => {
  return inProgressStatuses.includes(status)
}
