import React from 'react'
import { useDispatch } from 'react-redux'

import { useConfirmModal } from 'packages/common'
import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset, type AsyncState } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import { type Clean, type CleanPatchData } from 'app/store/cleans'
import { updateClean } from 'app/store/cleans/actions'
import { type UnknownAction } from '@reduxjs/toolkit'
import { useDecision } from '@optimizely/react-sdk'
import { Features } from 'packages/optimizely'

interface UseUpdateClean {
  showModal: () => void
  updateState: AsyncState<unknown>
}

export const useUpdateClean = (clean: Clean): UseUpdateClean => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { showGwToast } = useGwToast()
  const [decision] = useDecision(Features.CLEAN_IN_PROGRESS)

  const [updateState, updateCleanFn] = useAsyncFnWithReset(
    async (patchData: CleanPatchData, callbacks: ReduxActionCallbacks = {}) =>
      dispatch(updateClean(patchData, callbacks) as unknown as UnknownAction),
    [],
  )

  const handleUpdateClean = React.useCallback(() => {
    const date = createDateObject()

    const isComplete = clean.startedAt !== null || !decision?.enabled

    const patchData = {
      completedAt: isComplete ? date : undefined,
      id: clean.id,
      startedAt: !isComplete ? date : undefined,
    }

    updateCleanFn(patchData, {
      onSuccess: () => {
        showGwToast({ message: t(Slugs.taskUpdateSuccess) })
      },
    })
  }, [updateCleanFn, clean, showGwToast, t])

  const { showModal } = useConfirmModal({
    onConfirm: handleUpdateClean,
    slugs: {
      message:
        clean.startedAt === null
          ? t(Slugs.taskStartedModalBody)
          : t(Slugs.taskCompleteModalBody),
      title: t(Slugs.areYouSure),
    },
  })

  return {
    showModal,
    updateState,
  }
}
