import styled from '@emotion/styled'
import { upperFirst } from 'lodash/fp'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'

import {
  SanitizedHTML,
  MapsLink,
  Button,
  LockCodeButtonContainer,
  isTicketLockCodeCreationDisabled,
  LockCodeRequestType,
} from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import { type Ticket } from 'app/store/tickets'

import { CreateTicketDrawer } from '../../schedule/components/CleanDetail/CreateTicket/CreateTicketDrawer'
import { useTranslateTickets } from '../hooks'
import { useUpdateTicket } from '../hooks/useUpdateTicket'
import { isTicketCompleted, isTicketInProgress } from '../tickets.helpers'
import { TicketStatusBadge } from '../TicketStatusBadge'
import { AccessSection } from './AccessSection'
import { DatesSection } from './DatesSection'
import {
  Container,
  Description,
  ExternalLink,
  LockCodeBody,
  LockCodeRow,
  Section,
  StatusBadgeContainer,
  StatusBar,
  SubTitle,
  Row,
  TicketDetailBody,
  TicketDetailIcon,
  Title,
} from './TicketDetail.styles'
import { useDecision } from '@optimizely/react-sdk'
import { Features } from 'packages/optimizely'

const St = {
  Container,
  CreateTicketDrawer: styled(CreateTicketDrawer)`
    padding: 0 24px 16px 24px;
  `,
  Description,
  ExternalLink,
  LockCodeBody,
  LockCodeRow,
  Row,
  Section,
  StatusBadgeContainer,
  StatusBar,
  SubTitle,
  TicketDetailBody,
  TicketDetailIcon,
  Title,
}

const useTranslations = ({ lockCode, ticket }) => {
  const { t, ut } = useI18n()
  const [decision] = useDecision(Features.TICKET_IN_PROGRESS)

  const getLockCodeBody = () => {
    if (isTicketLockCodeCreationDisabled(ticket)) {
      return Slugs.disabledLockCodeBody
    }

    return lockCode
      ? Slugs.enabledLockCodeBodyWithCode
      : Slugs.enabledLockCodeBody
  }

  return {
    buttonText: t(
      isTicketCompleted(ticket.status)
        ? upperFirst(Slugs.completed)
        : isTicketInProgress(ticket.status) || !decision.enabled
          ? t(Slugs.markAsComplete)
          : t(Slugs.acknowledgeTicketButtonText),
    ),
    description: ut(Slugs.description),
    lockCodeBody: t(getLockCodeBody(), { lockCode }),
    lockCodes: t(Slugs.lockCodes, { lockCode }),
    requestedBy: t(Slugs.requestedBy),
  }
}

const iconTextStyles: React.CSSProperties = {
  fontWeight: 'bold',
  lineHeight: '16px',
  textTransform: 'uppercase',
}

export enum TicketDetailTestIds {
  button = 'TicketDetail__button',
  lockCodes = 'TicketDetail__lockCodes',
  statusBadgeContainer = 'TicketDetail__statusBadgeContainer',
  container = 'TicketDetail__container',
}

export interface TicketDetailProps {
  ticket: Ticket
}

export const TicketDetail: React.FC<TicketDetailProps> = ({ ticket }) => {
  const [lockCode, setLockCode] = React.useState('')
  const { translateSeverity } = useTranslateTickets()
  const strings = useTranslations({ lockCode, ticket })

  const {
    createdAt,
    description,
    dueDate,
    lockbox,
    requestedBy,
    severity,
    status,
    title,
    unit,
  } = ticket

  const { showModal, updateTicketState } = useUpdateTicket(ticket)

  const { address, city, name: unitName, state, unitCode, hasSmartLock } = unit

  return (
    <St.Container data-testid={TicketDetailTestIds.container}>
      <St.StatusBar>
        <SvgIcon
          centerItems={true}
          icon={IconName.tag}
          text={translateSeverity(severity)}
          textFontSize={12}
          textStyleOverrides={iconTextStyles}
        />
      </St.StatusBar>

      <St.Section>
        <St.StatusBadgeContainer
          data-testid={TicketDetailTestIds.statusBadgeContainer}
        >
          <TicketStatusBadge status={status} />
        </St.StatusBadgeContainer>

        <St.Title>
          <div>{title}</div>
          <div>
            {unitCode} - {unitName}
          </div>
        </St.Title>

        <St.SubTitle>
          {address},<br />
          {city}, {state}
        </St.SubTitle>

        <St.Row>
          <MapsLink address={address} city={city} state={state} />
        </St.Row>
      </St.Section>

      <St.Section>
        <Button
          buttonType={'secondary'}
          block={true}
          dataTestId={TicketDetailTestIds.button}
          isDisabled={isTicketCompleted(status)}
          isLoading={updateTicketState.loading}
          onPress={showModal}
        >
          {strings.buttonText}
        </Button>
      </St.Section>

      <St.Section>
        <St.Description>
          <b>{strings.description}:</b>
          {description && <SanitizedHTML html={description} />}
        </St.Description>
        <b>{strings.requestedBy}:</b> {requestedBy}
      </St.Section>

      <DatesSection createdAt={createdAt} dueDate={dueDate} />

      {/* Lock Codes */}
      {hasSmartLock && (
        <St.Section data-testid={TicketDetailTestIds.lockCodes}>
          <St.LockCodeRow>
            <St.TicketDetailIcon icon={IconName.lock} size={16} />
            <St.TicketDetailBody>
              <div>
                <strong>{strings.lockCodes}</strong>
              </div>
              <St.LockCodeBody>{strings.lockCodeBody}</St.LockCodeBody>
              <LockCodeButtonContainer
                itemId={ticket.id}
                type={LockCodeRequestType.ticket}
                onSetLockCode={setLockCode}
                isCreationDisabled={() =>
                  isTicketLockCodeCreationDisabled(ticket)
                }
              />
            </St.TicketDetailBody>
          </St.LockCodeRow>
        </St.Section>
      )}
      <AccessSection lockbox={lockbox} />

      <St.CreateTicketDrawer unit={unit} />
    </St.Container>
  )
}
