import { uniq } from 'lodash/fp'
import React from 'react'

import { Loader } from 'packages/common'
import { isAfter, isBefore } from 'packages/utils/dateHelpers'

import { type Clean } from 'app/store/cleans'
import { type Reservation } from 'app/store/reservations'

import { type UpcomingPreviousType } from '../../SchedulePage'
import { NewCleanCardContainer } from '../NewCleanCard'
import { NewResCardContainer } from '../NewResCard'
import * as St from './CleansTimeline.styles'

interface CompareDatesArgTypes {
  checkIn?: string
  effectiveDate?: string
}

const compareDates = (a: CompareDatesArgTypes, b: CompareDatesArgTypes) => {
  const sortFieldA = a.effectiveDate || a.checkIn
  const sortFieldB = b.effectiveDate || b.checkIn

  if (sortFieldA && sortFieldB) {
    if (isBefore(sortFieldB, sortFieldA)) {
      return 1
    }

    if (isAfter(sortFieldB, sortFieldA)) {
      return -1
    }
  }

  return 0
}

export enum CleansTimelineTestIds {
  container = 'CleansTimeline__container',
}

export interface CleansTimelineProps {
  cleanBuckets: Record<string, Clean[]>
  isPreviousLoading: boolean
  lastFetchTimestamp: number
  refetchCleans: () => void
  resBuckets: Record<string, Reservation[]>
  selected: UpcomingPreviousType
}

export const CleansTimeline: React.FC<CleansTimelineProps> = React.memo(
  ({
    cleanBuckets,
    isPreviousLoading,
    lastFetchTimestamp,
    refetchCleans,
    resBuckets,
    selected,
  }) => {
    const yearKeys = uniq([
      ...Object.keys(cleanBuckets),
      ...Object.keys(resBuckets),
    ])

    const sortedYearKeys =
      selected === 'upcoming' ? yearKeys.sort() : yearKeys.sort().reverse()

    return isPreviousLoading ? (
      <Loader />
    ) : (
      <St.ScheduleTimeline data-testid={CleansTimelineTestIds.container}>
        {sortedYearKeys.map(yearKey => {
          const cleanBucket = cleanBuckets[yearKey] || []
          const resBucket = resBuckets[yearKey] || []

          const yearBucket = [...cleanBucket, ...resBucket]

          const sortedYearBucket =
            selected === 'upcoming'
              ? yearBucket.sort(compareDates)
              : yearBucket.sort(compareDates).reverse()

          return (
            <React.Fragment key={yearKey}>
              <St.YearHeader>{yearKey}</St.YearHeader>

              <St.TimeStampBanner
                lastFetch={lastFetchTimestamp}
                onRefetch={refetchCleans}
              />

              {sortedYearBucket.map((value: Clean | Reservation) => {
                if ('effectiveDate' in value) {
                  return (
                    <St.CardContainer
                      key={value.id}
                      data-testid={`clean-card-${value.id}`}
                    >
                      <NewCleanCardContainer clean={value} />
                    </St.CardContainer>
                  )
                }

                return (
                  <St.CardContainer key={value.id}>
                    <NewResCardContainer reservationId={value.id} />
                  </St.CardContainer>
                )
              })}
            </React.Fragment>
          )
        })}
      </St.ScheduleTimeline>
    )
  },
)
