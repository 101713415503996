import styled from '@emotion/styled'
import React from 'react'

import type { Reservation } from 'app/store/reservations'
import type { Unit } from 'app/store/units'

import { ResHeader } from '../../ResHeader'
import { ResDateInfo } from '../ResDateInfo'
import { ResDetails } from '../ResDetails'
import { ResUnitInfo } from '../ResUnitInfo'

const St = {
  Content: styled.div`
    padding: 16px 32px 44px 32px;
  `,
  ResDrawerContent: styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
  `,
}

export interface ResDrawerContentProps {
  res: Reservation
  unit: Unit
}

export const ResDrawerContent: React.FC<ResDrawerContentProps> = React.memo(
  ({ res, unit }) => {
    return (
      <St.ResDrawerContent>
        <ResHeader res={res} />
        <St.Content>
          <ResUnitInfo unit={unit} />
          <ResDateInfo res={res} unitTimezone={unit.tz} />
          <ResDetails res={res} />
        </St.Content>
      </St.ResDrawerContent>
    )
  },
)
