enum Slugs {
  access = 'access',
  accessBody = 'accessBody',
  accessEmailDescription = 'accessEmailDescription',
  accessLog = 'accessLog',
  acknowledgeTicketButtonText = 'acknowledgeTicketButtonText',
  active = 'active',
  addAccess = 'addAccess',
  addAccessBody = 'addAccessBody',
  addAdditionalDetails = 'addAdditionalDetails',
  addPhoto = 'addPhoto',
  additionalDetails = 'additionalDetails',
  additionalHomeInfo = 'additionalHomeInfo',
  additionalInstructions = 'additionalInstructions',
  address = 'address',
  alarmCode = 'alarmCode',
  allCleans = 'allCleans',
  anErrorOccurred = 'anErrorOccurred',
  anErrorOccurredLong = 'anErrorOccurredLong',
  areYouSure = 'areYouSure',
  assignToMyself = 'assignToMyself',
  authErrorBody = 'authErrorBody',
  back = 'back',
  backup = 'backup',
  badgeB2b = 'badgeB2b',
  badgeEarly = 'badgeEarly',
  badgeLate = 'badgeLate',
  bath = 'bath',
  bathroom = 'bathroom',
  bed = 'bed',
  bedroom = 'bedroom',
  bookedOn = 'bookedOn',
  bookingDetails = 'bookingDetails',
  bookingStatusCompleted = 'bookingStatusCompleted',
  bookingStatusNotFinished = 'bookingStatusNotFinished',
  by = 'by',
  cancel = 'cancel',
  cannotCreateTicket = 'cannotCreateTicket',
  changeYourAvailability = 'changeYourAvailability',
  changeYourSchedule = 'changeYourSchedule',
  changedToPostStayClean = 'changedToPostStayClean',
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  clean = 'clean',
  cleanComplete = 'cleanComplete',
  cleanId = 'cleanId',
  cleanIdForUkg = 'cleanIdForUkg',
  cleanInProgress = 'cleanInProgress',
  cleanNotStarted = 'cleanNotStarted',
  cleanNotesTitle = 'cleanNotesTitle',
  cleanType = 'cleanType',
  cleans = 'cleans',
  codeDuration = 'codeDuration',
  complete = 'complete',
  completeCleanModalFooter = 'completeCleanModalFooter',
  completeCleanModalTitle = 'completeCleanModalTitle',
  completeTicketModalTitle = 'completeTicketModalTitle',
  completed = 'completed',
  confirm = 'confirm',
  confirmed = 'confirmed',
  contact = 'contact',
  copied = 'copied',
  copy = 'copy',
  copyFailed = 'copyFailed',
  copyLink = 'copyLink',
  createATicketDefaultDispositionDisplayValue = 'createATicketDefaultDispositionDisplayValue',
  createATicketDispositionErrorMessage = 'createATicketDispositionErrorMessage',
  createMaintenanceTicketHeader = 'createMaintenanceTicketHeader',
  createTicket = 'createTicket',
  createTicketBody = 'createTicketBody',
  createTicketMessageFailure = 'createTicketMessageFailure',
  createTicketSuccessMessage = 'createTicketSuccessMessage',
  created = 'created',
  createdBy = 'createdBy',
  date = 'date',
  days = 'days',
  deletePhoto = 'deletePhoto',
  description = 'description',
  disabledLockCodeBody = 'disabledLockCodeBody',
  dog = 'dog',
  double = 'double',
  due = 'due',
  editType = 'editType',
  email = 'email',
  emailAddress = 'emailAddress',
  emailDescription = 'emailDescription',
  emailPlaceholder = 'emailPlaceholder',
  emptyMonth = 'emptyMonth',
  enabledLockCodeBody = 'enabledLockCodeBody',
  enabledLockCodeBodyWithCode = 'enabledLockCodeBodyWithCode',
  end = 'end',
  endTime = 'endTime',
  errorTicketNotFound = 'errorTicketNotFound',
  errorTryAgainLater = 'errorTryAgainLater',
  firstAndLastName = 'firstAndLastName',
  firstName = 'firstName',
  freezeWindowBody = 'freezeWindowBody',
  full = 'full',
  futon = 'futon',
  gateCode = 'gateCode',
  generateLockCode = 'generateLockCode',
  genericErrorMsg = 'genericErrorMsg',
  goToHomeownerAccount = 'goToHomeownerAccount',
  guestCode = 'guestCode',
  guestContact = 'guestContact',
  guestDetails = 'guestDetails',
  guestStay = 'guestStay',
  guest = 'guest',
  gwLocalHostDeleteError = 'gwLocalHostDeleteError',
  gwLocalHostDeleteModalBody = 'gwLocalHostDeleteModalBody',
  gwLocalHostDeleteModalTitle = 'gwLocalHostDeleteModalTitle',
  gwLocalHostDeleteSuccess = 'gwLocalHostDeleteSuccess',
  gwLocalHostUpdateError = 'gwLocalHostUpdateError',
  gwLocalHostUpdateSuccess = 'gwLocalHostUpdateSuccess',
  half = 'half',
  hi = 'hi',
  historicalLog = 'historicalLog',
  historicalLogBody = 'historicalLogBody',
  homeAccess = 'homeAccess',
  homeInfo = 'homeInfo',
  homeIsCleaned = 'homeIsCleaned',
  homeownerAccountSupport = 'homeownerAccountSupport',
  hotTub = 'hotTub',
  hours = 'hours',
  iAgree = 'iAgree',
  inProgress = 'inProgress',
  invalidImage = 'invalidImage',
  inviteSent = 'inviteSent',
  irreversibleChangeWarning = 'irreversibleChangeWarning',
  itIsValidFrom = 'itIsValidFrom',
  items = 'items',
  king = 'king',
  lastName = 'lastName',
  lastUpdated = 'lastUpdated',
  laundry = 'laundry',
  letUsKnowCreateATicket = 'letUsKnowCreateATicket',
  listing = 'listing',
  localHost = 'localHost',
  lockCode = 'lockCode',
  lockCodeCreated = 'lockCodeCreated',
  lockCodeFormBody = 'lockCodeFormBody',
  lockCodeFormSuccessBody = 'lockCodeFormSuccessBody',
  lockCodeGenerator = 'lockCodeGenerator',
  lockCodeStartTime = 'lockCodeStartTime',
  lockCodeSupportText = 'lockCodeSupportText',
  lockCodes = 'lockCodes',
  lockbox = 'lockbox',
  logout = 'logout',
  markAsComplete = 'markAsComplete',
  maxOccupancy = 'maxOccupancy',
  midStay = 'midStay',
  moreDetailsOnHome = 'moreDetailsOnHome',
  moreTickets = 'moreTickets',
  myHomes = 'myHomes',
  myTickets = 'myTickets',
  name = 'name',
  needToRescheduleClean = 'needToRescheduleClean',
  network = 'network',
  night = 'night',
  noAccessCodes = 'noAccessCodes',
  noAssignedTickets = 'noAssignedTickets',
  noCleans = 'noCleans',
  noCodeToShow = 'noCodeToShow',
  noPhoto = 'noPhoto',
  noSmartLockCardText = 'noSmartLockCardText',
  noUnitAvailable = 'noUnitAvailable',
  noUnits = 'noUnits',
  noneAdded = 'noneAdded',
  notAcknowledged = 'notAcknowledged',
  notCompleted = 'notCompleted',
  notStarted = 'notStarted',
  notYet = 'notYet',
  notes = 'notes',
  notifications = 'notifications',
  notificationsBody = 'notificationsBody',
  oopsChangesNotSaved = 'oopsChangesNotSaved',
  openMap = 'openMap',
  other = 'other',
  owner = 'owner',
  ownerHold = 'ownerHold',
  pageNotFound = 'pageNotFound',
  paused = 'paused',
  phone = 'phone',
  phoneDescription = 'phoneDescription',
  phoneNumber = 'phoneNumber',
  phonePlaceholder = 'phonePlaceholder',
  pleaseLetUsKnow = 'pleaseLetUsKnow',
  pleaseVerify = 'pleaseVerify',
  postStay = 'postStay',
  postStayClean = 'postStayClean',
  postStayCleanComplete = 'postStayCleanComplete',
  previous = 'previous',
  primary = 'primary',
  primaryHkDeleteModalBody = 'primaryHkDeleteModalBody',
  primaryHkDeleteModalTitle = 'primaryHkDeleteModalTitle',
  primaryHkDeleted = 'primaryHkDeleted',
  primaryHkUpdated = 'primaryHkUpdated',
  primaryHousekeeper = 'primaryHousekeeper',
  primaryHousekeeperBody = 'primaryHousekeeperBody',
  priorReservation = 'priorReservation',
  privacyNotice = 'privacyNotice',
  privacyNoticeBody = 'privacyNoticeBody',
  privacyNoticeTitle = 'privacyNoticeTitle',
  profile = 'profile',
  queen = 'queen',
  questions = 'questions',
  reportMaintenanceIssue = 'reportMaintenanceIssue',
  requestTimeOff = 'requestTimeOff',
  requestedBy = 'requestedBy',
  required = 'required',
  rescheduleAClean = 'rescheduleAClean',
  rescheduleACleanBody = 'rescheduleACleanBody',
  save = 'save',
  schedule = 'schedule',
  seeAProblem = 'seeAProblem',
  seeAnIssue = 'seeAnIssue',
  seeExamples = 'seeExamples',
  send = 'send',
  settings = 'settings',
  severity = 'severity',
  severityExamplesMediumExample = 'severityExamplesMediumExample',
  severityExamplesMediumLabel = 'severityExamplesMediumLabel',
  severityExamplesNote = 'severityExamplesNote',
  severityExamplesPriorToNextGuestExample = 'severityExamplesPriorToNextGuestExample',
  severityExamplesPriorToNextGuestLabel = 'severityExamplesPriorToNextGuestLabel',
  severityExamplesTitle = 'severityExamplesTitle',
  severityExamplesUrgentExample = 'severityExamplesUrgentExample',
  severityExamplesUrgentLabel = 'severityExamplesUrgentLabel',
  severityLow = 'severityLow',
  severityMedium = 'severityMedium',
  severityMediumNote = 'severityMediumNote',
  severityPlanned = 'severityPlanned',
  severityPriorNextGuestNote = 'severityPriorNextGuestNote',
  severityPriorToNextGuest = 'severityPriorToNextGuest',
  severityToday = 'severityToday',
  severityUrgent = 'severityUrgent',
  severityUrgentNote = 'severityUrgentNote',
  share = 'share',
  shareBody = 'shareBody',
  shareButton = 'shareButton',
  shareVia = 'shareVia',
  sharedCleanExpiredBody = 'sharedCleanExpiredBody',
  sharedCleanExpiredTitle = 'sharedCleanExpiredTitle',
  shortDescription = 'shortDescription',
  showCleans = 'showCleans',
  showLess = 'showLess',
  showMore = 'showMore',
  smartLock = 'smartLock',
  sofaBed = 'sofaBed',
  start = 'start',
  startATicket = 'startATicket',
  startCleanButtonText = 'startCleanButtonText',
  startTime = 'startTime',
  stepWithNumber = 'stepWithNumber',
  stop = 'stop',
  submit = 'submit',
  support = 'support',
  supportAvailablity = 'supportAvailablity',
  supportCardBody = 'supportCardBody',
  taskCompleteModalBody = 'taskCompleteModalBody',
  taskStartedModalBody = 'taskStartedModalBody',
  taskUpdateSuccess = 'taskUpdateSuccess',
  thankYou = 'thankYou',
  thisHomeIs = 'thisHomeIs',
  ticketCompleteModalBody = 'ticketCompleteModalBody',
  ticketReassigned = 'ticketReassigned',
  ticketSeverityMedium = 'ticketSeverityMedium',
  ticketSeverityPriorNextGuest = 'ticketSeverityPriorNextGuest',
  ticketSeverityUrgent = 'ticketSeverityUrgent',
  ticketStartedModalBody = 'ticketStartedModalBody',
  ticketStatusActionComplete = 'ticketStatusActionComplete',
  ticketUpdateSuccess = 'ticketUpdateSuccess',
  tickets = 'tickets',
  time = 'time',
  title = 'title',
  today = 'today',
  todo = 'todo',
  toolTipB2b = 'toolTipB2b',
  toolTipEarly = 'toolTipEarly',
  toolTipLate = 'toolTipLate',
  toolTipPotentialB2b = 'toolTipPotentialB2b',
  training = 'training',
  twin = 'twin',
  unit = 'unit',
  unitInfo = 'unitInfo',
  unitTimezone = 'unitTimezone',
  unknown = 'unknown',
  upcoming = 'upcoming',
  update = 'update',
  updateAvailability = 'updateAvailability',
  updatesAvailable = 'updatesAvailable',
  updating = 'updating',
  vacasa = 'vacasa',
  vacasaHold = 'vacasaHold',
  view = 'view',
  viewHistoricalLog = 'viewHistoricalLog',
  viewHome = 'viewHome',
  whatNeedsAttention = 'whatNeedsAttention',
  yourLockCodeIs = 'yourLockCodeIs',
  youreBackOnline = 'youreBackOnline',
  youreOffline = 'youreOffline',
}

export default Slugs
